import React from "react";
import { Table } from "./Table";
import { TableStyles } from "../style/globalStyles";
import { exportCSVFile } from "../../jsonToCsvDownload";
export const SkippedOrdersTable = ({ skippedorders }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: (skippedorder) =>
          skippedorder.name ? skippedorder.name : "NA",
      },
      {
        Header: "Mobile",
        accessor: (skippedorder) =>
          skippedorder.mobile_no ? skippedorder.mobile_no : "NA",
      },
      {
        Header: "E-mail",
        accessor: (skippedorder) =>
          skippedorder.email_id ? skippedorder.email_id : "NA",
      },
      {
        Header: "Source",
        accessor: (skippedorder) =>
          skippedorder.source ? skippedorder.source : "Web",
      },
      {
        Header: "KMS points",
        accessor: (skippedorder) =>
          skippedorder.kms_driven_points
            ? skippedorder.kms_driven_points
            : "NA",
      },
      {
        Header: "Search Details",
        accessor: (skippedorder) =>
          skippedorder.search_details
            ? skippedorder.search_details.tripType +
              "," +
              skippedorder.search_details.fromLocation +
              ", SD- " +
              skippedorder.search_details.startDate +
              ", ED- " +
              skippedorder.search_details.endDate
            : "NA",
      },
      {
        Header: "Created On",
        accessor: (skippedorder) => {
          return skippedorder.created ? skippedorder.created : "NA";
        },
      },
    ],
    []
  );

  var data = React.useMemo(() => skippedorders, []);

  var tableRender = (
    <Table
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          // onClick: (e) => console.log("row clicked", rowInfo),
        };
      }}
      // Pass the page url here to navigate to onClick
      //   path={"/ride"}
      // pass filename to enable download .csv
      filename={"skipped_orders"}
      extras={true}
      columns={columns}
      data={data}
    />
  );

  return (
    <>
      <TableStyles>{skippedorders ? tableRender : " "}</TableStyles>
    </>
  );
};
