import React, { useEffect, useState } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import { fetchSkippedOrders, fetchSkippedOrdersFilter } from "../state";
import { GlobalStyle } from "../style/globalStyles";
import { Layout } from "../components/Layout";
import { SkippedOrdersInputForm } from "../components";
import { SkippedOrdersTable } from "../components/SkippedOrdersTable";
import { BallBeat } from "react-pure-loaders";
import { RidesSection } from "../style";
function SkippedOrdersContainer({ skippedOrders, fetchSkippedOrders }) {
  // console.log(skippedOrders);
  const [state, setState] = useState();
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.auth.user);
  const loginBool = Boolean(loginData.portalUserId);

  useEffect(() => {
    // console.log("fetchSkippedOrders being called", state, bidStatusTypes);
    if (!loginBool) {
      navigate("/login");
    }
    if (skippedOrders.skippedorders.length === 0) {
      fetchSkippedOrders();
    }
  }, [fetchSkippedOrders]);

  const handleChange = (e) => {
    dispatch(fetchSkippedOrdersFilter(e.target.value));

    // console.log("inside hc", state);
  };
  // console.log("after hc", state);

  return (
    <>
      <GlobalStyle />
      <Layout>
        <RidesSection>
          <h1>skipped orders List</h1>
          <br />
          <SkippedOrdersInputForm />
          {skippedOrders.loading ? (
            <h2>
              Loading skipped orders{" "}
              <span>
                <BallBeat color={"#123abc"} loading={skippedOrders.loading} />
              </span>{" "}
            </h2>
          ) : skippedOrders.error ? (
            <>
              <h2>ERROR ⚠️ FETCHING skipped orders</h2>
              <br />
              <p style={{ color: "grey" }}>{skippedOrders.error}</p>
              <br />
              <br />
            </>
          ) : (
            <>
              <br />
              <br />
              {skippedOrders.skippedorders.length > 0 ? (
                <SkippedOrdersTable
                  skippedorders={skippedOrders.skippedorders}
                />
              ) : (
                "No skipped orders found ⚠️"
              )}
            </>
          )}
        </RidesSection>
      </Layout>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    skippedOrders: state.skippedorders,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSkippedOrders: () => dispatch(fetchSkippedOrders()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SkippedOrdersContainer);
